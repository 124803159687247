import * as React from 'react'
import Layout from '../../templates/layout'
import SloganPage from '../../components/molecules/SloganPage'
import Seo from '../../helpers/seo'
import HeaderContentSolution from '../../components/organisms/HeaderContentSolution'
import ContentSolution from '../../components/organisms/ContentSolution'
import CardsInformation from '../../components/molecules/CardsInformation'
import innovationJson from './innovation.json'
import ContactForm from '../../components/organisms/ContactForm'
import CasesRelational from '../../components/organisms/CasesRelational'
import { assignIDToSolutionsJson } from '../../helpers/assignIDToSolutionsJson'

const ServicesInnovation = () => {
  const { discovery, realidadeAumentada, inteligenciaArtifical, iot, relational } =
    assignIDToSolutionsJson(innovationJson)

  const cardContent = [
    {
      id: 1,
      heading: 'Discovery e MVPs_',
      content: () => {
        return discovery.map((data) => {
          return <ContentSolution description={data.description} key={data.id} />
        })
      },
      label: 'orange-dark',
      selected: 'close',
    },
    {
      id: 2,
      heading: '(AI) Inteligência Artificial_',
      content: () => {
        return inteligenciaArtifical.map((data) => {
          return <ContentSolution description={data.description} key={data.id} />
        })
      },
      label: 'orange-light',
      selected: 'close',
    },
    {
      id: 3,
      heading: '(AR) Realidade Aumentada_',
      content: () => {
        return realidadeAumentada.map((data) => {
          return <ContentSolution description={data.description} key={data.id} />
        })
      },
      label: 'orange-dark',
      selected: 'close',
    },
    {
      id: 4,
      heading: '(IoT) Internet das Coisas_',
      content: () => {
        return iot.map((data) => {
          return <ContentSolution description={data.description} key={data.id} />
        })
      },
      label: 'orange-light',
      selected: 'close',
    },
  ]

  return (
    <Layout page="ServicesInnovation">
      <SloganPage title={'Inovação'} type={'innovation'} />
      <HeaderContentSolution
        icon={'squads'}
        description={`<p>A inovação é o motor do progresso empresarial. Nossa gama de serviços em inovação abrange desde a
        criação de MVPs e exploração de tecnologias como Realidade Aumentada e Inteligência Artificial, até a
        integração de soluções de IoT. Nosso foco é ajudar a identificar e implementar oportunidades de inovação
        que transformam processos e experiências, mantendo seu negócio na vanguarda da tecnologia.</p>`}
      />
      <CardsInformation cardContent={cardContent} />
      <ContactForm />
      <CasesRelational relationalData={relational} currentCategory="Inovação" />
    </Layout>
  )
}

export const Head = () => <Seo title="Serviços" />

export default ServicesInnovation
